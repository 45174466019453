import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';

import BannerCatalog from 'common/source/containers/BannerCatalog';
import CatalogBody from 'common/source/containers/CatalogBody';
import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

class CatalogPage extends React.Component {
  render() {
    const { data } = this.props;
    const { page } = data;
    const { frontmatter } = page;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Catálogo | ${config.siteTitle}`} path="/catalogo" pageNode={page} />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <BannerCatalog cover={frontmatter.cover} title={frontmatter.title}>
              {frontmatter.subtitle}
            </BannerCatalog>
            <CatalogBody node={page} />
            {/* <Container>
              <OsramContentWrapper>
                <div className="osram-heading">
                  <h2>Aplicaciones profesionales e industriales</h2>
                </div>
                <Categories categories={frontmatter.categories} />
                <VideoModal />
                <Modal />
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </OsramContentWrapper>
            </Container> */}
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default CatalogPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CatalogQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "catalogo" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        subtitle
        type
        cover
        thumbnail
        categories {
          category_slug
          title
          subtitle
          featured
          cover
          thumbnail
        }
      }
    }
  }
`;
