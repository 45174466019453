import styled from 'styled-components';

const CatalogCategoryBodyWrapper = styled.div`
  line-height: 2;
  ${'' /* margin-bottom: 10px; */}
  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.colors.white};
  }
  ul {
    li {
      list-style-type: inherit;
      margin-left: 20px;
    }
  }

  .grid-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 15px;
    @media only screen and (max-width: 667px) {
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    padding: 25px 30px;
    h2,
    h3,
    h4 {
      margin: 0;
      margin-top: 10px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.headingColor};
    }
    @media only screen and (max-width: 667px) {
      text-align: center;
      display: block;
      margin-bottom: 15px;
    }
  }
  .info-card {
    line-height: 1;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
    }
    margin-bottom: 15px;
    ${'' /* max-width: 400px; */}
    .info-background {
      height: 100%;
      background-color: ${({ theme }) => theme.colors.background};
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .img-wrapper {
        height: 250px;
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: 100%;
          width: 100%;
          display: block;
          object-fit: cover;
          border: 0;
        }
      }
      .txt-wrapper {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 15px 30px;
        min-height: 250px;
        h2,
        h3,
        h4 {
          margin: 0;
          margin-top: 10px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.headingColor};
        }
        h2 {
          font-size: 1.8rem;
        }
        h3,
        h4 {
          font-size: 1.5rem;
        }
      }
    }
    p {
      margin-top: 20px;
      line-height: 1.5;
    }
  }

  .btn {
    &.primary {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: #ff7b00;
      min-height: 46px;
      min-width: 48px;
      border-radius: 3px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      text-transform: capitalize;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 15px;
      border: 0;
      transition: all 0.3s ease;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      height: 40px;
      min-height: auto;
      font-size: 13px;
      border-radius: 4px;
      &:hover {
        box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow};
      }
    }
  }
`;

export default CatalogCategoryBodyWrapper;
