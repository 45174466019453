import styled, { css } from 'styled-components';

const CategoryGridWrapper = styled.div``;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: 'a a';
  grid-gap: 10px;
  margin-top: 10px;
  ${({ template }) =>
    template &&
    css`
      grid-template-columns: ${template};
    `}
    ${({ single }) =>
      single &&
      css`
        grid-template-areas: 'a';
      `}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    display: block;
  }

`;

export const CategoryWrapper = styled.div`
  position: relative;
  padding: 3.75rem 1.875rem;
  min-height: 50vh;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  z-index: 1;
  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}
  ${({ cover }) =>
    cover &&
    css`
      background-image: url(${cover});
      background-repeat: no-repeat;
      background-size: cover;
      /* background-position: center; */
      &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.normalLayer};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    `}
  @media only screen and (max-width: 667px) {
    min-height: 45vh;
    margin-top: 10px;
  }
  h2 {
    color: #fff;
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  button {
    transition: 0.2s all;
    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: ${({ theme }) => theme.colors.textColor};
      box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    }
  }
`;

export default CategoryGridWrapper;
