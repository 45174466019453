import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import CategoryGridWrapper, { GridContainer, CategoryWrapper } from './categorygrid.style';

const CategoryGrid = ({ categories, prefix, lastFeatured }) => {
  const lastObject = categories[categories.length - 1];
  const lastItem = { ...lastObject };
  const filteredCat = categories.filter(cat => cat.category_slug !== lastItem.category_slug);
  const currentCategories = lastFeatured ? filteredCat : categories;
  return (
    <CategoryGridWrapper>
      <GridContainer>
        {currentCategories.length > 0 &&
          currentCategories.map(category => {
            const { category_slug, cover, title, subtitle } = category;
            const path = prefix ? `${prefix}/${category_slug}` : `/${category_slug}`;
            return (
              <CategoryWrapper height="70vh" cover={cover} key={`key${prefix}${category_slug}`}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                <Link to={path}>
                  <Button title="Ir a Catálogo" variant="outlined" />
                </Link>
              </CategoryWrapper>
            );
          })}
      </GridContainer>
      {lastFeatured && lastItem && (
        <GridContainer single>
          <CategoryWrapper height="70vh" cover={lastItem.cover}>
            <h2>{lastItem.title}</h2>
            <p>{lastItem.subtitle}</p>
            <Link
              to={prefix ? `${prefix}/${lastItem.category_slug}` : `/${lastItem.category_slug}`}
            >
              <Button title="Ir a Catálogo" variant="outlined" />
            </Link>
          </CategoryWrapper>
        </GridContainer>
      )}
    </CategoryGridWrapper>
  );
};

export default CategoryGrid;
